import React, { useRef } from "react";

function Lights() {
  return (
    <>
      <ambientLight intensity={0.5} />

      <directionalLight position={[0, 20, 0]} intensity={0.1} />
      <directionalLight position={[0, 20, -20]} intensity={0.1} />
      <directionalLight position={[0, 25, 60]} intensity={0.1} />
     {/*  <directionalLight
        lookAt={[0, 0, 0]}
        position={[5, 0, 50]}
        intensity={0.05}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[0, 0, -50]}
        intensity={0.3}
        color="#ffffff"
      />
      <directionalLight
        lookAt={[0, 0, 0]}
        position={[-5, 0, -50]}
        intensity={0.05}
        color="#ffffff"
      /> */}
    </>
  );
}

export default Lights;
